* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  letter-spacing: 0.2px;
  font-family: 'Be Vietnam Pro', -apple-system, BlinkMacSystemFont, sans-serif;
}

:root {
  --base-font-size: 16px;
  font-size: var(--base-font-size);
}

::-webkit-scrollbar {
  width: 10px; /* 1px wider than Lion. */
  /*background-color: #fafafa;*/
  /* This is more usable for users trying to click it. */
  /*-webkit-border-radius: 100px;*/
}
::-webkit-scrollbar:horizontal {
  height: 10px;
}

button {
  border: solid;
}

input:focus {
  outline: none;
}

body {
  overflow: hidden;
  height: 100%;
  margin: 0;
  line-height: 1.4;
  font-family: 'Be Vietnam Pro', -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 300;
  color: #4e516a;
}

a {
  color: #333;
  text-decoration: none;
}

button,
input,
p {
  color: #4e516a;
}

/* TODO: MOVE TO STYLES.SCSS AND ASSIGN VALUES TO VARIABLES */

.focusable-text {
  cursor: pointer;
  border: 1px solid transparent;
  padding: 5px;
}

/* TODO: CHANGE TO USE LIGHTEN/DARKEN SASS FUNCTIONS */
.hoverable-button {
  opacity: 0.9;
  transition: all ease-in-out 200ms;
}

.hoverable-button:hover {
  opacity: 1;
  transform: translate(0px, -5px);
}

.create-availability-button {
  background: #53DAB0;
}

.create-event-button {
  background: #4362EA;
}

.disabled-icon {
  opacity: 0.3;
  /* transition: opacity 0.3s; */
  color: #4e516a;
}

.side-menu-div {
  padding-left: -10px;
}

.side-menu-div:hover {
  padding-left: 15px;
}

.rbc-header {
  border-left: 1px solid transparent;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid transparent;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 10px;
}

.rbc-day-slot .rbc-event-content {
  font-weight: 600;
  word-wrap: normal;
}

.weekly-toolbar-header {
  display: flex;
  flex-direction: row;
}

.emoji-mart {
  border: none;
}

.text-link-top-bar {
  text-decoration: underline;
  cursor: pointer;
}

.text-link-top-bar:hover {
  color: orange;
  transition: 0.2s;
}
